<template>
  <div class="mcc_desc con_area_bgcolor">
    <div class="mcc_desc_con con_area">
      <div class="w11402">
        <h2>Mcc Convention Center</h2>
        <h3>华会所</h3>
        <p class="justify_align">
          MCCT has progressed alongside the community it serves. Throughout the
          three years of the Covid-19 pandemic, it utilized some social media
          platforms to provide its communication and outreach services. We
          managed to secure an office space which also serves as a venue for
          community gatherings, events and activities operated by MCCT.
          Therefore, in the following year, MCCT was able to utilize both online
          and in-person means to deliver its commsions.
        </p>
        <div>
          <div class="mcc_desc_con_item">
            <div class="justify_align">
              <h2>Food</h2>
              <h3>美食</h3>
              <p>
                Dim Sum, Snacks, Festival Foods, Signature dishes, Special
                Recommendations
              </p>
              <p>点心·小吃·节日美食·招牌午餐·特别推荐</p>
            </div>
            <div>
              <div class="card">
                <header class="card__thumb">
                  <a href="#"><img src="../../assets/img/mcc/jrms.jpg" /></a>
                </header>
                <div class="card__body">
                  <h2 class="card__title">
                    <a href="#">Chinese Cooking Classes</a>
                  </h2>
                  <div class="card__subtitle">节日食品制作</div>
                  <div class="card__description">
                    <p>
                      Twice a month cooking of Chinese seasonal and festival
                      food, such as Dumplings, Buns, Baozi, Moon cakes,
                      Yuanxiao, Zongzi ect.
                    </p>
                    <div class="mcc_desc_con_item_address">
                      <h6>详情请洽</h6>
                      <p>地址：21 King Street, Palmerston North</p>
                      <p>电话 : (06) 3562629</p>
                      <p>手机：027 8819818</p>
                      <p>微信 ：Wen631090</p>
                      <p>电子邮箱 : mccttrust@gmail.com</p>
                    </div>
                    <!-- <p>
                      每月两次烹饪中国时令和节日食品，如饺子、包子、月饼、元宵、粽子等。
                    </p> -->
                  </div>
                </div>
              </div>
              <div class="card">
                <header class="card__thumb">
                  <a href="#"><img src="../../assets/img/mcc/jc.jpg" /></a>
                </header>
                <div class="card__body">
                  <h2 class="card__title">
                    <a href="#">Charity Fundraising Dinner</a>
                  </h2>
                  <div class="card__subtitle">慈善募捐聚餐</div>
                  <div class="card__description">
                    <p>
                      Organized by Mcct for a fundraising purpose from time to
                      time, open to our members as well the public of the
                      community.
                    </p>
                    <div class="mcc_desc_con_item_address">
                      <h6>详情请洽</h6>
                      <p>地址：21 King Street, Palmerston North</p>
                      <p>电话 : (06) 3562629</p>
                      <p>手机：027 8819818</p>
                      <p>微信 ：Wen631090</p>
                      <p>电子邮箱 : mccttrust@gmail.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mcc_desc_con_item">
            <div class="justify_align">
              <h2>Wellbeing</h2>
              <h3>保健</h3>
              <p>Tea, soup, juice, Herb tea</p>
              <p>品茶·汤·果汁·酒会</p>
            </div>
            <div>
              <div class="card">
                <header class="card__thumb">
                  <a href="#"><img src="../../assets/img/mcc/pc.jpg" /></a>
                </header>
                <div class="card__body">
                  <h2 class="card__title">
                    <a href="#">Chinese Tea Ceremony</a>
                  </h2>
                  <div class="card__subtitle">中国茶道</div>
                  <div class="card__description">
                    <p>
                      Demonstrations on all types of Chinese teas and the
                      culture that people use them.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <header class="card__thumb">
                  <a href="#"><img src="../../assets/img/mcc/jiu.jpg" /></a>
                </header>
                <div class="card__body">
                  <h2 class="card__title">
                    <a href="#">Chinese Wine Culture</a>
                  </h2>
                  <div class="card__subtitle">中国传统酒文化</div>
                  <div class="card__description">
                    <p>
                      Showcasing all types of Chinese wines and the cultures
                      that use them.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mcc_desc_con_item">
            <div class="justify_align">
              <h2>Games</h2>
              <h3>游戏</h3>
              <p>
                Puzzles, Poker, Mahjong, Chess, Go, Balls, Karaoke, Werewolf,
                Online Games, TV, Stories
              </p>
              <p>
                猜谜·拼图·扑克·麻将·象棋·围棋·球类·卡拉ok·狼人杀·网游·电视·故事
              </p>
            </div>
            <div>
              <div class="card">
                <header class="card__thumb">
                  <a href="#"
                    ><img src="../../assets/img/mcc_center/xiangqi.jpg"
                  /></a>
                </header>
                <div class="card__body">
                  <h2 class="card__title">
                    <a href="#">Chinese Chess</a>
                  </h2>
                  <div class="card__subtitle">中国象棋</div>
                  <div class="card__description">
                    <p>
                      Chinese chess is vast and profound, with infinite wisdom
                      hidden in its small board. Playing chess emphasizes wisdom
                      and strategy. Chess is like life, and the pieces are like
                      the stages of life, making people deeply moved!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mcc_desc_con_item">
            <div class="justify_align">
              <h2>Education</h2>
              <h3>培训</h3>
              <p>
                <span>Language:</span>
                NCEA Chinese exam / crash travel practical Chinese / life
                Chinese / new comers' English / English for work
              </p>
              <p>
                <span>Recreation:</span>
                Tai Chi / Calligraphy / Painting / Dance / Musical Instruments /
                Fitness
              </p>
              <p>
                <span>Skills:</span>
                driving / cooking / gardening / sewing / employment interview /
                accounting
              </p>
              <p>
                <span>Workshop:</span>
                Insurance / Welfare / Security / Psychology / Domestic violence
                / Health / Business/ Pension / Tax / Immigration
              </p>
              <p>
                <span>语言：</span>
                NCEA中文考试 / 速成旅游实用中文 / 生活中文 / 新人英语 / 工作英语
              </p>
              <p>
                <span>兴趣：</span>
                太极 / 书法 / 画画 / 舞蹈 / 乐器 / 健身
              </p>
              <p>
                <span>技能：</span>
                开车 / 做饭 / 花园 / 缝纫 / 养生 / 就业 / 面试 / 会计
              </p>
              <p>
                <span>讲座：</span>
                保险 / 福利 / 治安 / 心理 / 家暴 / 养生 / 生意 / 养老 / 税务 /
                移民
              </p>
            </div>
            <div>
              <div class="card">
                <header class="card__thumb">
                  <a href="#"><img src="../../assets/img/mcc/syzw.jpg" /></a>
                </header>
                <div class="card__body">
                  <h2 class="card__title">
                    <a href="#">ADULT CHINESE</a>
                    <p>Intensive Holiday Programme</p>
                  </h2>

                  <div class="card__subtitle">实用中文</div>
                  <div class="card__description">
                    <p>
                      Chinese radicals<br />
                      Intensive spoken <br />
                      Rapid Literacy in Chinese<br />
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <header class="card__thumb">
                  <a href="#"><img src="../../assets/img/mcc/xfzwfd.jpg" /></a>
                </header>
                <div class="card__body">
                  <h2 class="card__title">
                    <a href="#">CHINESE NCEA</a>
                    <p>Intensive Holiday Programme</p>
                  </h2>
                  <div class="card__subtitle">学分中文辅导</div>
                  <div class="card__description">
                    <p>
                      Get ready for your NCEA exam !<br />
                      Intensive immersion course over the holidays 2 hours of
                      classes each day - $25
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <header class="card__thumb">
                  <a href="#"><img src="../../assets/img/mcc/xxydy.jpg" /></a>
                </header>
                <div class="card__body">
                  <h2 class="card__title">
                    <a href="#">ONE-ON-ONE</a>
                    <p>Intensive Study Aid</p>
                  </h2>
                  <div class="card__subtitle">学习一对一</div>
                  <div class="card__description">
                    <p>
                      Get ready for your NCEA exam !<br />
                      Intensive study aid on science, biological, statistic,
                      accounting, chemistry with a teacher specially for you --
                      $40 per day
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <header class="card__thumb">
                  <a href="#"><img src="../../assets/img/mcc/cxzyy.jpg" /></a>
                </header>
                <div class="card__body">
                  <h2 class="card__title">
                    <a href="#">NEW COMERS’ ENGLISH</a>
                    <p>Intensive Holiday Programme</p>
                  </h2>
                  <div class="card__subtitle">初学者英语</div>
                  <div class="card__description">
                    <p>
                      Use the new language of where you stay!<br />
                      Intensive immersion course 2 hours of classes each day -
                      $25 per day
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mcc_desc_con_item">
            <div class="justify_align">
              <h2>Goods</h2>
              <h3>交易</h3>
              <p>Second hand, group purchasing, fairs</p>
              <p>二手·团购·代购·推介会</p>
            </div>
            <div>
              <div class="card">
                <header class="card__thumb">
                  <a href="#"><img src="../../assets/img/mcc/csesd.jpg" /></a>
                </header>
                <div class="card__body">
                  <h2 class="card__title">
                    <a href="#">Charity second-hand store</a>
                  </h2>
                  <div class="card__subtitle">慈善二手店</div>
                  <div class="card__description">
                    <p>
                      The main business is to receive, process, and sell unused
                      items donated by people, providing them with love and
                      convenience for purchasing second-hand items.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mcc_desc_con_item">
            <div class="justify_align">
              <h2>Service</h2>
              <h3>服务</h3>
              <p>
                Haircut, Beauty, Skin Care, Foot Reflexology, Massage, Scraping,
                Moxibustion, Physical Examination, Psychological Testing, House
                help
              </p>
              <p>理发·美容·护肤·足疗·按摩·刮痧·艾灸·查体·心理测试·家政</p>
            </div>
            <div>
              <div class="card">
                <header class="card__thumb">
                  <a href="#"
                    ><img src="../../assets/img/mcc_center/meirong.jpg"
                  /></a>
                </header>
                <div class="card__body">
                  <h2 class="card__title">
                    <a href="#">Cosmetology</a>
                  </h2>
                  <div class="card__subtitle">美容</div>
                  <div class="card__description">
                    <p>Give you beautiful health, healthy beauty!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mcc_desc_con_item">
            <div class="justify_align">
              <h2>Referrer</h2>
              <h3>推荐</h3>
              <p>
                Insurance, Laws, Welfare, Public Security, Finance, Travel
                Agency, Study Abroad, Immigration, Recruitment, health Care,
                Pension, Translation
              </p>
              <p>
                保险·律师·福利·治安·财务·旅行社·留学·移民·猎头·医疗·养老·翻译
              </p>
            </div>
            <div>
              <div class="card">
                <header class="card__thumb">
                  <a href="#"><img src="../../assets/img/mcc/rjtl.jpg" /></a>
                </header>
                <div class="card__body">
                  <h2 class="card__title">
                    <a href="#">Day care services</a>
                  </h2>
                  <div class="card__subtitle">日间托老服务</div>
                  <div class="card__description">
                    <p>
                      Provide to our aged members and their families with
                      frailty and dementia Twice a week, a four-hour club event.
                      Let those elder people have their a social and fun time
                      and the care givers will alleviate the fatigue for a bit
                      while
                    </p>
                    <div class="mcc_desc_con_item_con">
                      <h6>活动时间</h6>
                      <p>每周一，周四 10.30am -- 1.30pm</p>
                      <h6>活动内容</h6>
                      <p>
                        聊天、听歌/戏曲、做操、生日庆祝、纸牌、抛豆袋/圈<br />
                        午茶、水果、点心、午饭
                      </p>
                    </div>
                    <div class="mcc_desc_con_item_address">
                      <h6>详情请洽</h6>
                      <p>地址：21 King Street, Palmerston North</p>
                      <p>电话 : (06) 3562629</p>
                      <p>手机：027 8819818</p>
                      <p>微信 ：Wen631090</p>
                      <p>电子邮箱 : mccttrust@gmail.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mcc_desc_con_item_xmsf">
            <h2>Fee For Service</h2>
            <h3>项目收费</h3>
            <div>
              <el-table
                :data="tableData"
                border
                stripe
                style="width: 100%"
                :header-cell-style="headerCellStyle"
              >
                <el-table-column fixed prop="project" label="项目">
                </el-table-column>
                <el-table-column prop="money" label="金额标准">
                </el-table-column>
                <el-table-column prop="pay" label="负责人"> </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="mcc_desc_con_item_xmsf" style="border: none">
            <h2>Contact Information</h2>
            <h3>联系方式</h3>
            <div>
              <div class="aboutus_tell_con">
                <div class="aboutus_tell">
                  <div>
                    <p>
                      <span class="iconfont icon-xianxingdianhua"></span>
                    </p>
                    <div>
                      <p>Chinese Hotline :</p>
                      <h3>+64 6 3562629</h3>
                    </div>
                  </div>
                </div>
                <div class="aboutus_tell">
                  <div>
                    <p>
                      <span class="iconfont icon-shouji"></span>
                    </p>
                    <div>
                      <p>Mobile phone :</p>
                      <h3>+64 27 8819818</h3>
                    </div>
                  </div>
                </div>
                <div class="aboutus_tell">
                  <div>
                    <p>
                      <span class="iconfont icon-iconfontunie62c"></span>
                    </p>
                    <div>
                      <p>Email :</p>
                      <h3>mccttrust@gmail.com</h3>
                    </div>
                  </div>
                </div>
                <div class="aboutus_tell">
                  <div>
                    <p>
                      <span class="iconfont icon-icon_weixin-logo"></span>
                    </p>
                    <div>
                      <p>Wechat :</p>
                      <h3>Wen631090</h3>
                    </div>
                  </div>
                </div>
                <div class="aboutus_tell">
                  <div>
                    <p>
                      <span class="iconfont icon-lianshu"></span>
                    </p>
                    <div>
                      <p>Facebook :</p>
                      <h3>Mcct Trust</h3>
                    </div>
                  </div>
                </div>
                <div class="aboutus_tell">
                  <div>
                    <p>
                      <span class="iconfont icon-yumingyuwangzhan"></span>
                    </p>
                    <div>
                      <p>Website :</p>
                      <h3>www.manawatuchinese.org.nz</h3>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="aboutus_tell_con">
                <div class="aboutus_tell">
                  <div>
                    <span class="iconfont icon-xianxingdianhua"></span>
                    <div>
                      <p>Chinese Hotline :</p>
                      <h3>+64 6 3562629</h3>
                    </div>
                  </div>
                </div>
                <div class="aboutus_tell">
                  <div>
                    <span class="iconfont icon-shouji"></span>
                    <div>
                      <p>Mobile phone :</p>
                      <h3>+64 27 8819818</h3>
                    </div>
                  </div>
                </div>
                <div class="aboutus_tell">
                  <div>
                    <span class="iconfont icon-iconfontunie62c"></span>
                    <div>
                      <p>Email :</p>
                      <h3>mccttrust@gmail.com</h3>
                    </div>
                  </div>
                </div>
                <div class="aboutus_tell">
                  <div>
                    <span class="iconfont icon-icon_weixin-logo"></span>
                    <div>
                      <p>Wechat :</p>
                      <h3>Wen631090</h3>
                    </div>
                  </div>
                </div>
                <div class="aboutus_tell">
                  <div>
                    <span class="iconfont icon-lianshu"></span>
                    <div>
                      <p>Facebook :</p>
                      <h3>Mcct Trust</h3>
                    </div>
                  </div>
                </div>
                <div class="aboutus_tell">
                  <div>
                    <span class="iconfont icon-yumingyuwangzhan"></span>
                    <div>
                      <p>Website :</p>
                      <h3>www.manawatuchinese.org.nz</h3>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [
        { project: "使用场地", money: "$15/hour", pay: "负责人" },
        {
          project: "开课办班",
          money: "$80/人10堂； $40/一对一",
          pay: "与老师平分",
        },
        { project: "一次性商务讲座", money: "$50/次", pay: "主讲方" },
        { project: "一次性制作课", money: "$5/人+材料", pay: "参与人" },
        { project: "福利·服务讲座", money: "$5/人", pay: "听众" },
        {
          project: "老人·儿童 daycare",
          money: "每人$20/次+食物茶点费",
          pay: "参与人",
        },
        {
          project: "游戏",
          money: "每人$5--$15/次",
          pay: "参与人视时间长短和游戏道具",
        },
        {
          project: "留学生课外活动",
          money: "",
          pay: "视活动内容、消费额、时间",
        },
        { project: "中医出诊", money: "$25/人次+药费", pay: "与医生洽" },
        {
          project: "商品/产品代销",
          money: "差价 或 提成 或摊位费",
          pay: "与商家洽",
        },
        {
          project: "生活服务",
          money: "$5--$25/人次",
          pay: "视服务内容、时间、材料",
        },
        {
          project: "出任调解/代办/翻译",
          money: "$20--$50/次",
          pay: "视时间、复杂程度、结果",
        },
        { project: "会员制", money: "年费；充值卡", pay: "" },
      ],
    };
  },
  mounted() {},
  methods: {
    tz(name, paramsvalue) {
      $(window).scrollTop(0);
      if (paramsvalue) {
        this.$router.push({
          name: name,
          query: { param: paramsvalue },
        });
      } else {
        this.$router.push({
          name: name,
        });
      }
    },
    // 表格表头样式
    headerCellStyle() {
      return "background:#01b5d1;color:#fff;text-align:center;";
    },
  },
};
</script>

<style>
.mcc_desc {
  width: 100%;
}
.mcc_desc_con {
  width: 100%;
}
.mcc_desc_con .w11402 {
  /* width: 1140px; */
  /* width: calc(100vw - 36vw); */
  margin: 0 auto;
  overflow: hidden;
}
.mcc_desc_con .w11402 > h2 {
  /* text-transform: uppercase; */
  width: 100%;
  line-height: 0.1em;
  margin: 30px 0;
  font-size: 3rem;
}
.mcc_desc_con .w11402 > h3 {
  font-size: 2.5rem;
  font-weight: 500;
}
.mcc_desc_con .w11402 > p {
  font-size: 1.8rem;
  margin: 2rem 0;
}
.mcc_desc_con_item {
  width: 100%;
  padding: 5rem 0;
  border-bottom: 1px solid #cccccc;
  display: flex;
  /* justify-content: space-between; */
}
.mcc_desc_con_item > div:nth-child(1) {
  width: 39%;
  padding-left: 1rem;
  padding-right: 3rem;
  box-sizing: border-box;
}
.mcc_desc_con_item > div:nth-child(1) > h2,
.mcc_desc_con_item_xmsf > h2 {
  font-size: 2.2rem;
}
.mcc_desc_con_item > div:nth-child(1) > h3,
.mcc_desc_con_item_xmsf > h3 {
  font-size: 1.9rem;
  margin: 20px 0;
  font-weight: 500;
}
.mcc_desc_con_item > div:nth-child(1) > p {
  font-size: 1.7rem;
  margin-top: 10px;
  line-height: 150%;
}
.mcc_desc_con_item > div:nth-child(1) > p > span {
  font-weight: 600;
  margin-right: 5px;
}
.mcc_desc_con_item > div:nth-child(2) {
  width: 60%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.mcc_desc_con_item_con {
  margin-top: 1rem;
  font-size: 1.4rem;
  line-height: 220%;
}
.mcc_desc_con_item_con > h6 {
  font-size: 1.6rem;
  font-weight: 600;
  color: #013942;
  margin-top: 1rem;
}
.mcc_desc_con_item_address {
  margin: 1rem 0;
  font-size: 1.4rem;
  line-height: 220%;
}
.mcc_desc_con_item_address > h6 {
  font-size: 1.6rem;
  font-weight: 600;
  color: #013942;
  margin: 1rem 0;
}
.mcc_desc_con_item_address > p {
  margin-top: 0 !important;
}
.card {
  width: 48%;
  background-color: #fff;
  /* background-color: #f9f9f9; */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  /* overflow: hidden; */
  -webkit-transition: box-shadow 0.5s;
  transition: box-shadow 0.5s;
  margin: 2rem 0;
  border-radius: 10px;
  overflow: hidden;
  /* border: 1px solid orange; */
}
.card a {
  color: inherit;
  text-decoration: none;
}

.card:hover {
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.3);
}
.card__thumb {
  height: 215px;
  overflow: hidden;
  background-color: #000;
  -webkit-transition: height 0.5s;
  transition: height 0.5s;
}
.card__thumb img {
  width: 100%;
  height: 100%;
  display: block;
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.5s, transform 0.5s;
  transition: opacity 0.5s, transform 0.5s, -webkit-transform 0.5s;
}
.card:hover .card__thumb {
  height: 100px;
}

.card:hover .card__thumb img {
  opacity: 0.6;
  /* -webkit-transform: scale(1.2);
  transform: scale(1.2); */
  object-fit: cover;
}

/**
* BODY
**/
.card__body {
  position: relative;
  height: 75px;
  padding: 20px 20px 30px;
  -webkit-transition: height 0.5s;
  transition: height 0.5s;
}
.card:hover .card__body {
  height: 190px;
  overflow-y: auto;
}

.card__category {
  position: absolute;
  top: -25px;
  left: 0;
  height: 25px;
  padding: 0 15px;
  background-color: coral;
  color: #fff;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 25px;
}

.card__title {
  margin: 0;
  padding: 0 0 1rem 0;
  color: #000;
  font-size: 2.2rem;
  font-weight: 500;
  text-transform: uppercase;
}
.card__title > p {
  font-size: 1.7rem;
  /* margin-top: 0.8rem; */
}
.card__subtitle {
  margin: 0;
  font-size: 1.9rem;
  /* color: coral; */
  color: #01b5d1;
}

.card__description {
  text-align: justify;
  /* text-indent: 2em; */
  position: absolute;
  left: 20px;
  right: 20px;
  /*bottom: 56px;*/
  margin: 0;
  padding: 0;
  color: #666c74;
  /* line-height: 27px; */
  line-height: 200%;
  opacity: 0;
  -webkit-transform: translateY(45px);
  transform: translateY(45px);
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}
.card__description p {
  font-size: 1.3rem;
  margin: 0;
  margin-top: 1rem;
}
.card:hover .card__description {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.mcc_desc_con_item_xmsf {
  width: 100%;
  padding: 5rem 0;
  border-bottom: 1px solid #cccccc;
}
.mcc_desc_con_item_xmsf > div {
  padding: 2rem 4rem;
  box-sizing: border-box;
}
</style>